
// blog
#blog {
    .blog-slide {
      margin-top: 86px;
      .glide__slides {
        height: 366px;
        align-items: center;
      }
      .glide__slide {
        display: flex;
        align-items: center;
        .blog-item {
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
            height: 204px;
            border-radius: 10px;
            margin-bottom: 15px;
            transition: all .5s ease !important;
          }
          p {
            font-size: 18px;
            margin-bottom: 0;
            color: #6f7070;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 43px;
          }
        }
        &--active {
          .blog-item {
            img {
              height: 277px !important;
              margin-bottom: 17px;
            }
            p {
              font-size: 20px;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
      .glide__arrows{
        margin-top: 40px;
      }
    }
    .blog-slide-1{
      margin-top: 86px;
      .glide__slides {
        height: 366px;
        align-items: center;
      }
      .glide__slide {
        display: flex;
        align-items: center;
        .blog-item {
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
            height: 204px;
            border-radius: 10px;
            margin-bottom: 15px;
            transition: all .5s ease !important;
          }
          p {
            font-size: 18px;
            margin-bottom: 0;
            color: #6f7070;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 43px;
          }
        }
        &--active {
          .blog-item {
            img {
              height: 277px !important;
              margin-bottom: 17px;
            }
            p {
              font-size: 20px;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
      .glide__arrows{
        margin-top: 40px;
      }
    }
    .blog-slide-2{
      margin-top: 86px;
      .glide__slides {
        height: 366px;
        align-items: center;
      }
      .glide__slide {
        display: flex;
        align-items: center;
        .blog-item {
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
            height: 204px;
            border-radius: 10px;
            margin-bottom: 15px;
            transition: all .5s ease !important;
          }
          p {
            font-size: 18px;
            margin-bottom: 0;
            color: #6f7070;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 43px;
          }
        }
        &--active {
          .blog-item {
            img {
              height: 277px !important;
              margin-bottom: 17px;
            }
            p {
              font-size: 20px;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
      .glide__arrows{
        margin-top: 40px;
      }
    }
    .blog-slide-3{
      margin-top: 86px;
      .glide__slides {
        height: 366px;
        align-items: center;
      }
      .glide__slide {
        display: flex;
        align-items: center;
        .blog-item {
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
            height: 204px;
            border-radius: 10px;
            margin-bottom: 15px;
            transition: all .5s ease !important;
          }
          p {
            font-size: 18px;
            margin-bottom: 0;
            color: #6f7070;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 43px;
          }
        }
        &--active {
          .blog-item {
            img {
              height: 277px !important;
              margin-bottom: 17px;
            }
            p {
              font-size: 20px;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
      .glide__arrows{
        margin-top: 40px;
      }
    }
    .blog-slide-4{
      margin-top: 86px;
      .glide__slides {
        height: 366px;
        align-items: center;
      }
      .glide__slide {
        display: flex;
        align-items: center;
        .blog-item {
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
            height: 204px;
            border-radius: 10px;
            margin-bottom: 15px;
            transition: all .5s ease !important;
          }
          p {
            font-size: 18px;
            margin-bottom: 0;
            color: #6f7070;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 43px;
          }
        }
        &--active {
          .blog-item {
            img {
              height: 277px !important;
              margin-bottom: 17px;
            }
            p {
              font-size: 20px;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
      .glide__arrows{
        margin-top: 40px;
      }
    }
  }
  
  @media screen and ( max-width: map-get( $grid-breakpoints, lg ) + 1 ){  
  
      // blog
      #blog{
        .blog-slide{
            margin-top: 24px;
            .glide__slides{
                height: 351px;
            }
            .glide__slide{
                .blog-item{
                    width: 100%;
                    margin: unset;
                    .h4{
                        font-size: 16px;
                        min-height: 38px;
                    }
                    img{                    
                        transition: all .6s ease-in-out;
                    }
                }
                &--active{
                    .blog-item{
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            .glide__arrows{
                margin-top: 60px;
            }
        }
        .blog-slide-1{
            margin-top: 24px;
            .glide__slides{
                height: 351px;
            }
            .glide__slide{
                .blog-item{
                    width: 100%;
                    margin: unset;
                    .h4{
                        font-size: 16px;
                        min-height: 38px;
                    }
                    img{                    
                        transition: all .6s ease-in-out;
                    }
                }
                &--active{
                    .blog-item{
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            .glide__arrows{
                margin-top: 60px;
            }
        }
        .blog-slide-2{
            margin-top: 24px;
            .glide__slides{
                height: 351px;
            }
            .glide__slide{
                .blog-item{
                    width: 100%;
                    margin: unset;
                    .h4{
                        font-size: 16px;
                        min-height: 38px;
                    }
                    img{                    
                        transition: all .6s ease-in-out;
                    }
                }
                &--active{
                    .blog-item{
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            .glide__arrows{
                margin-top: 60px;
            }
        }
        .blog-slide-3{
            margin-top: 24px;
            .glide__slides{
                height: 351px;
            }
            .glide__slide{
                .blog-item{
                    width: 100%;
                    margin: unset;
                    .h4{
                        font-size: 16px;
                        min-height: 38px;
                    }
                    img{                    
                        transition: all .6s ease-in-out;
                    }
                }
                &--active{
                    .blog-item{
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            .glide__arrows{
                margin-top: 60px;
            }
        }
        .blog-slide-4{
          margin-top: 24px;
          .glide__slides{
            height: 351px;
          }
          .glide__slide{
              .blog-item{
                  width: 100%;
                  margin: unset;
                  .h4{
                      font-size: 16px;
                      min-height: 38px;
                  }
                  img{                    
                      transition: all .6s ease-in-out;
                  }
              }
              &--active{
                  .blog-item{
                      width: 100%;
                      margin-left: 0;
                  }
              }
          }
          .glide__arrows{
            margin-top: 60px;
          }
      }
    }
  
  }
  
  @media screen and ( min-width: 1700px ){
      // blog
      #blog{
          .blog-slide{
              .glide__slides{
                  height: 443px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 277px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 354px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-1{
              .glide__slides{
                  height: 443px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 277px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 354px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-2{
              .glide__slides{
                  height: 443px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 277px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 354px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-3{
              .glide__slides{
                  height: 443px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 277px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 354px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-4{
              .glide__slides{
                  height: 443px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 277px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 354px !important;
                          }
                      }
                  }
              }
          }
      }
  }
  
  @media screen and ( min-width: 2000px ){
      // blog
      #blog{
          .blog-slide{
              .glide__slides{
                  height: 564px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 404px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 477px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-1{
              .glide__slides{
                  height: 564px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 404px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 477px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-2{
              .glide__slides{
                  height: 564px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 404px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 477px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-3{
              .glide__slides{
                  height: 564px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 404px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 477px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-4{
              .glide__slides{
                  height: 564px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 404px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 477px !important;
                          }
                      }
                  }
              }
          }
      }
  }
  
  @media screen and ( min-width: 3000px ){
      // blog
      #blog{
          .blog-slide{
              .glide__slides{
                  height: 639px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 450px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 550px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-1{
              .glide__slides{
                  height: 639px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 450px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 550px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-2{
              .glide__slides{
                  height: 639px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 450px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 550px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-3{
              .glide__slides{
                  height: 639px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 450px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 550px !important;
                          }
                      }
                  }
              }
          }
          .blog-slide-4{
              .glide__slides{
                  height: 639px;
              }
              .glide__slide{
                  .blog-item{
                      img{
                          height: 450px;
                      }
                  }
                  &--active{
                      .blog-item{
                          img{
                              height: 550px !important;
                          }
                      }
                  }
              }
          }
      }
  }
  