@media screen and ( max-width: map-get( $grid-breakpoints, lg ) + 1 ){  
    // experience
    #experience{
        margin-top: 53px;
        .top{
            .left{
                padding: 0;
            }
            .right{
                margin: 20px 0 24px;
            }
        }
        .bottom{
            height: auto;
            margin-top: 0;
            .right{
                padding: 0;
                margin: 0 0 70px !important;
                height: 298px;
            }
            .left{
                padding: 0;
                &::before{
                    bottom: -70px;
                }
                &::after{
                    top: -34px;
                }
            }
        }
    }
    // accommodation
    #accommodation, #accom{
        margin-top: -10px;
        background-color: #FFFFFF;
        .top{
            background-color: #FFFFFF;
            height: 573px;
        }
        .bottom{
            margin-top: -354px;
        }
        .glide__slides{
            height: 338px;
        }
        .glide__slide{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .accommodation-item, .accom-item{
                border-radius: 10px;
                height: 215px !important;
                .hotel-name{
                    padding: 0 20px !important;
                    p{
                        font-size: 16px !important;
                        line-height: 19px !important;
                        letter-spacing: -.30px;
                    }
                    .hotel-description {
                        letter-spacing: 0;
                    }
                }
            }  
            &--active{
                .accommodation-item, .accom-item {
                    height: 338px !important;
                    width: 100% !important;
                    margin-left: unset !important;
                    .hotel-name{
                        p{
                            font-size: 16px !important;
                            line-height: 19px !important;
                            letter-spacing: -.30px;
                        }
                        .hotel-description {
                            letter-spacing: 0;
                        }
                    }
                }
            }
        }
        .glide__arrows{
          position: unset;
          margin-bottom: 14px;
        }
    }
    // experience
    #experience-slider{
        padding: 60px 0 80px;
        height: 1126px;
        .glide{
            .glide__slides{
                img{
                    max-width: 700px;
                }
            }
        }
        .bottom{
            margin-top: 0;
            .right{
                margin: 20px 0 24px;
                .left{
                    padding: 0;
                    top: 470px;
                    left: 0%;
                    &::after{
                        top: -20px;
                        right: 40px;
                    }
                    &::before{
                        bottom: -70px;
                        left: 0px;
                    }
                    .glide__bullets{
                        .glide__bullet{
                            margin: 0 0 20px 0;
                        }
                    }
                }
            }
        }
    }
    // hotel packages
    #hotel-packages{
        padding-bottom: 125px;
        .top{
            .col-lg-7{
                padding: 0;
            }
        }
        .bottom{
            padding: 0 !important;
            margin: 0 !important;
            max-width: none !important;
        }
        .blured-slider{
            width: 100%;
            .glide__track{
                padding-top: 40px;
            }
            .glide__slides{
                align-items: center;
                overflow: visible;
            }
            .glide__slide{
                .hotel-item{
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    filter: blur(5px);
                    border-radius: 10px;
                    .slider-top{
                        height: 278px;
                        width: 100%;
                        background-color: #ffffff;
                        border-radius: 10px 10px 0 0;
                        img{
                            height: 100%;
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                    .slider-bottom{
                        padding: 40px 25px 0;
                        background-color: #ffffff;
                        border-radius: 0 0 10px 10px;
                        p{
                            &:nth-child(2){
                                margin: 20px 0 40px;
                                line-height: 21px;
                            }
                        }
                    }
                }  
                &--active{
                    .hotel-item{
                        width: 100%;
                        height: 100%;
                        margin-left: 0;
                        filter: blur(0px);
                    }
                }
            }
        }
    }
    // video text
    #video-text{
        height: 74vh;
        .bg-holder{
            position: absolute;
        }
        .video-main{
            position: unset;
            min-height: 242px;
            padding: 36px 45px 47px 23px;
            .h2{
                font-size: 20px;
                line-height: 24px;
            }
            .h7{
                font-size: 16px;
                line-height: 21px;
            }
            &::after{
                top: 28px;
                right: 16px;
            }
            &::before{
                bottom: 20px;
                left: 23px;
            }
        }
    }
    // tab section
     #culture-tab{
        margin-bottom: 110px;
        .bottom{
            margin-top: 63px;
            .cart-tabs-container{
                padding: 0 !important;
                max-width: none;
            }
            .cart-tabs-header{
                border-bottom: none;
                margin-bottom: 40px;
                padding: 0px;
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                height: 43px;
                .accordion-box{    
                    padding-right: 20px;
                    padding-bottom: 14px;
                    border-bottom: .5px solid #707070;
                    &:last-child{
                        padding-right: 20px;
                    }
                    &:first-child{
                        padding-left: 20px;
                    }
                    p{
                        margin-bottom: 0;
                        padding-bottom: 0px;
                    }
                    &[aria-expanded="true"]::after{
                        height: 5px;
                        bottom: -17px;    
                        width: 100%;
                        margin-left: 0%;
                    }
                }
            }
            .left-right-component{
                .left{
                    padding: 0;
                    margin-bottom: 40px;
                    img{
                        height: 314px;
                    }
                }
                .right{
                    padding: 0 !important;
                    .h3{
                        font-size: 20px;
                    }
                    .h7{
                        margin: 20px 0 40px;
                    }
                    &::before{
                        bottom: -70px;
                        left: 0px;
                        width: 20px;
                        height: 30px;
                    }
                }
            }
        }
     }
     // customers
     #customers{
        padding-top: 80px;
        .bottom-container{
            padding: 0 !important;
            max-width: none;
        }
        #customers-slide{
            margin-top: 40px;
            .glide__slides{
                padding-top: 85px;
            }
            .customers-slider-item{
                padding: 85px 42px 65px 45px;
                img{
                    top: -84px;
                }
                .h7{
                    line-height: 21px;
                    font-size: 16px;
                }
                &::after{
                    top: 17px;
                }
            }
            .glide__bullets{
                display: flex;
                margin-top: 40px;
                .glide__bullet{
                    margin: 0;
                    background-color: #B8BABC;
                    height: .5px;
                    width: 50px;
                    border-radius: 100px !important;
                    &.glide__bullet--active{
                        background-color: #B41A83;
                        height: 3px;
                        width: 50px;
                        border-radius: 100px;
                        margin-top: -1px;
                    }
                }
            }
            .glide__arrows{
                margin-top: 80px;
            }
        }
    }
    // app
    #app{
        height: 364px;
        .left{
            .h3{
                font-size: 16px;
            }
            .h7{
                font-size: 16px;
                margin: 20px 0 10px !important;
            }
        }
    }
    // work with us
    #work-with-us{
        min-height: 670px;
        .left{
           margin: 40px 0 100px; 
           padding: 0 !important;
           .h3{
            font-size: 20px;
           }
           .h7{
            font-size: 16px;
           }
           &::after{
               content: "";
               background-image: url('../../img/L-leke.png');
               background-repeat: no-repeat;
               background-size: cover;
               position: absolute;
               top: -30px;
               right: 0px;
               width: 20px;
               height: 30px;
           }
           &::before{
               content: "";
               background-image: url('../../img/L-leke.png');
               background-repeat: no-repeat;
               background-size: cover;
               position: absolute;
               bottom: -60px;
               left: 0px;
               transform: rotate(180deg);
               width: 20px;
               height: 30px;
           }
        }
        .right{
            padding: 0 !important;
        }
    }
    // faq
    #faq{
        padding: 80px 0;
        .h2{
            font-size: 20px;
            margin-bottom: 30px;
            font-family: 'Neurial Grotesk Regular';
        }
        .accordion-button{
            padding: 20px 0 20px 0;
            .h3{
                color: #B41A83;
            }
            &[aria-expanded="true"]{
                p{
                    font-weight: bold;
                }
            }
        }
        .accordion-body{
            margin-bottom: 65px;
        }
    }
}
@media screen and ( max-width: map-get( $grid-breakpoints, md ) + 1 ){  
    // accommodation
    #accommodation, #accom{
        .container{
            padding: 0;
            margin: 0;
            max-width: none !important;
        }
    }
}
@media screen and ( max-width: map-get( $grid-breakpoints, sm ) + 1 ){  
    // experience
    #experience-slider{
        height: 1000px;
        .bottom{
            .right{
                .left{
                    top: 263px;
                }
            }
        }
    }
    // app
    #app{
        height: auto;
        padding: 40px 0;
        margin-top: 0px;
        .left{
            .h3{
                font-size: 16px;
            }
            .h7{
                font-size: 16px;
                margin: 20px 0 10px !important;
            }
        }
        .app-phone{
            position: relative;
            overflow: hidden;
            img{
                width: 81vh;
                height: auto !important;
                right: -5%;
                position: relative;
            }
        }
        .mobile-app{
            margin-top: 63px;
            a{
                &:first-child{
                    margin-right: 16px;
                    img{                
                        width: 111px;
                        height: 38px;
                    }
                }
            }
        }
    }
}

@media screen and ( max-width: 400px ){
    // accommodation
    #accommodation, #accom{
        .glide__slide{
            .accom-item{
                .hotel-name{
                    height: 80px !important;
                }
            }  
        }
    }
}
@media screen and ( min-width: 2000px ){
    // app
    #app{
        height: 800px;
    }
}

@media screen and ( min-width: 2200px ){
    // video section
    #video-text{
        .video-main{
            top: 14vh;
            left: 13vh;
            padding: 60px 72px;
            max-width: 550px;
            min-height: 400px;
            &::after{
                top: 6%;
            }
            .h2{
                font-size: 36px;
            }
            .h7{
                font-size: 24px;
                line-height: 24px;
            }
        }
    }
}
