
#main-header{
    height: calc( 100vh - 108px );
    padding: 0;
    margin-bottom: 60px;
    .container{
        position: relative;
        z-index: 1;
    }
    .header-slider{
        img{
            object-fit: cover;
        }
    }
    .glide__arrows{
        position: absolute;
        right: 98px;
        bottom: 50px;
        z-index: 2;
        button{
            &.glide__arrow--right{
                margin-left: 22px !important;
            }
            &.glide__arrow--left{
                margin-right: 0px !important;
            }
        }
    }
    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 56%); 
        z-index: 1;
    }
}
.header-bottom{
    position: relative;
    top: -112px;
    z-index: 1;
    margin: 0 auto;
    padding: 0;
    display: none;
    bottom: -100%;
    transition: all .5s ease;
    #header-close-btn{
        display: none;
        cursor: pointer;
        position: relative;
        z-index: 1;
        width: 12.39px;
        height: 12.39px;
        top: 13px;
        left: -16px;
    }
    &.active{
        background: #FFF;
        position: fixed !important;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center !important;   
        z-index: 99999 !important;
        padding: 0 !important;
        margin: 0 !important;
        transition: all .5s ease;
        top: unset;
        bottom: 0;
        left: 0;
        right: 0; 
        width: 100%;
        height: 120px;
        max-width: none;
        box-shadow: 0px -4px 6px #00000016;
        .barboon-box{
            max-width: 700px;
            margin-right: 0;
            align-items: center !important;
            justify-content: center !important;
        }
        .box-left{
            min-width: 700px;
            height: 50px;
            padding: 13px 28px 13px 32px;
        }
        #search-btn{
            p{
                line-height: 24px;
            }
        }
        .arrow-right{
            display: none;
        }
        .box-right{
            display: none;
        }
        #header-close-btn{
            display: flex;
        }
    }
    .arrow-right{
        width: 20px;
    }
}
.barboon-box{
    padding: 0;
    color: #FFFFFF;
    margin-right: 8.5%;
}
.box-left{
    background-color: #B41A83;
    height: 72px;
    border-radius: 10px;
    padding: 23px 28px 20px 32px;
    .item{
        border-right: 1px solid rgba(255, 255, 255, .48);;
        padding-right: 24px;
        margin-right: 24px;
        &:first-child{
            padding-right: 38px;
            padding-left: 0 !important;
        }
        &:nth-child(3){
            border-right: unset;
            padding-right: 0px;
            margin-right: 0px;
        }
        &:last-child{
            border-right: unset;
            padding-right: 0px;
            margin-right: 0px;
            margin-left: auto !important;
        }
    }
    #search-btn{
        min-width: 78px;
        background-color: #FFFFFF;
        border-radius: 7px;
        p{
            font-size: 14px;        
            color: #B41A83;
            letter-spacing: -1px;
            line-height: 30px;
        }
    }
    .arrow{
        width: 7px;
        height: 12px;
        margin: 0 11px;
        margin-bottom: 2px;
    } 
    .date{
        a{
            padding: 0;
            border: none !important;
            box-shadow: none !important;
            &:focus-visible{
                outline-offset: 0px !important;
                outline: 0 !important;
            }
        }
        input{
            border: none;
            background: transparent;
            color: #FFFFFF;
            max-width: 69px;
            min-width: unset;
            padding: 0;
            margin-bottom: 2px;
            font-size: 14px;
            font-family: 'Neurial Grotesk Regular';
            cursor: pointer;
            &::placeholder {
                color: #FFF;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: #FFF;
            }
              
            &::-ms-input-placeholder {
                color: #FFF;
            }
            &:focus-visible{
                outline-offset: 0px !important;
                outline: 0 !important;
            }
        }
    }
    #decrease-btn{
        margin-left: 22px;
        font-size: 16px;
        color: #FFFFFF !important;
        font-family: 'Neurial Grotesk Regular';
    }
    #increase-btn{
        font-size: 16px;
        color: #FFFFFF !important;
        font-family: 'Neurial Grotesk Regular';
    }
    #guest-count{
        font-size: 14px;
        margin: 0 10px !important;
        color: #FFFFFF !important;
        font-family: 'Neurial Grotesk Regular';
    }
    .select2{
        display: flex;
        height: 24px;
    }
}
.datepicker{
    table{
        width: 100%;
        thead{
            tr{
                &:nth-of-type(2){
                    height: 36px;
                }
                th{
                    transition: all .3s ease;
                    position: relative;
                    color: #333333;
                    font-weight: 500;
                    border-radius: 0;
                    &.prev,&.next{
                        font-size: 0px;
                        line-height: 0;
                        z-index: 99999;
                        &::before{
                            content: '';
                            top: 14px;
                            right: -16px;
                            width: 7px;
                            height: 11px;
                            display: block;
                            position: absolute;
                            background-image: url('../../img/right-arrow-black.svg');
                            background-repeat: no-repeat;
                            transform: rotate(180deg);
                            transition: all .3s linear;
                        }
                        &:hover{
                            font-size: 0px;
                            width: 20px;
                            background-color: transparent!important;
                        }
                    }
                    &.next{
                        &::before{
                            right: 46px;
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    width: 36px;
                    height: 32px;
                    border-radius: 0;
                    transition: background .2s ease-in,color .2s ease-in;
                    &.active{
                        background-color: $blue!important;
                        background-image: none;
                        &:hover{
                            background-color: darken($blue, 10)!important;
                            background-image: none;
                        }
                    }
                    span{
                        transition: background .2s ease-in,color .2s ease-in;
                        &.active{
                            background-color: $blue!important;
                            background-image: none;
                            color:$white;
                            &:hover{
                                background-color: darken($blue, 10)!important;
                                background-image: none;
                                color: $gray-100;
                            }
                        }
                    }
                }
            }
        }
    }
}
#city{
    display: none;
}
.select2{
    cursor: pointer;
}
.select2-search{
    display: none;
    height: 0;
}
#select2-city-container {
    font-size: 14px;
    font-family: 'Neurial Grotesk Regular';
}
.select2-results{
    position: relative;
    top: 0;
}
.select2-results__options{
    max-height: 164px;
    width: 147px;
    padding: 20px 0 20px 22px;
    overflow: auto;
    background-color: #F7F7F7;
    position: relative;
    z-index: 99999;
    top: 0px;
    li{
    background-color: #F7F7F7;
    color: #1D1D1D;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Neurial Grotesk Regular';
    cursor: pointer;
    &:first-child{
        display: none;
    }
    &:last-child{
        margin-bottom: 0;
    }
    }
}
.selection{
    position: relative;
}
.select2-selection{
    &[aria-expanded="true"]{
        .select2-selection__arrow b{
            transform: rotate(180deg) scaleX(-1);
        }
    }
}
.select2-container--default .select2-selection--single .select2-selection__arrow b{
    content: "";
    position: absolute;
    background-image: url('../../img/bottom-arrow.svg');
    background-repeat: no-repeat;
    background-size: cover;
    top: 49%;
    right: -15px;
    width: 8px;
    height: 4px;
    margin-left: 6.8px;
    transition: transform .3s linear;
}
.box-right{
    max-width: 222px;
    background-color: #B41A83;
    height: 72px;
    border-radius: 8px;
    padding: 9px 43px 13px 27px;
    p{
        font-size: 12px;
        line-height: 1.2;
        margin-bottom: 0;
    }
    .money{
        font-size: 18px;
        font-family: 'Neurial Grotesk Medium';
        span{
            font-size: 14px;
            margin-right: 5px;
            position: relative;
            text-align: center;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: -3%;
              right: 0;
              width: 106%;
              border-top: 1px solid #FFFFFF;
            }
        }
    }
}
.header-left{
    p{
        font-size: 48px;
        font-weight: bold;
        color: white;
    }
}
.header-right{
    p{
        font-size: 16px;
        color: white;
        &:first-child{
            margin-bottom: 0px;
        }
        &:last-child{
            font-size: 12px;
        }
    }
}
.sticky-button{
    background-color: #B41A83;
    padding: 0 18px;
    border-radius: 5px;
    position: fixed;
    display: flex;
    opacity: 1;
    right: 1%;
    bottom: 35vh;
    justify-content: center;
    z-index: 99999;
    transform: rotate(270deg);
    transition: all .5s ease;
    a{
		display: flex;
	}
	p{
        line-height: 36px;
        font-size: 14px;
        margin-bottom: 0;
        color: #FFFFFF;
    }
    a:hover{
        p{
            color: #FFFFFF !important;
        }
    }
    .close-button{
       margin-left: 10px;
       font-size: 14px;
       line-height: 36px;
       color: #FFFFFF !important;
       font-family: 'Neurial Grotesk Regular';
    }
}
@media screen and ( max-width: 1440px ){
    #main-header{
        .glide__arrows{
            right: 32px;
            bottom: 60px;
            button{
                &.glide__arrow--right{
                    margin-left: 16px !important;
                }
            }
        }
    }

}
@media screen and ( min-width: 1300px ){
    .barboon-box{
        margin-right: 0;
    }
}
@media screen and ( max-width: 1300px ){
    .box-left{
        padding: 18px 24px;
        height: 60px;
        .item{
            padding-right: 18px;
            margin-right: 18px;
            &:first-child{
                padding-right: 30px;
            }
        }
        #search-btn{
            min-width: 74px;
            p{
                font-size: 13px;        
                line-height: 24px;
            }
        }
        .arrow{
            margin: 0 9px;
        } 
        .date{
            input{
                font-size: 13px;
            }
        }
        #decrease-btn{
            margin-left: 18px;
            font-size: 14px;
        }
        #increase-btn{
            font-size: 14px;
        }
        #guest-count{
            font-size: 13px;
        }
    }
    .box-right{
        height: 60px;
        p{
            font-size: 10px;
        }
        .money{
            font-size: 14px;
            span{
                font-size: 12px;
            }
        }
    }
}
@media screen and ( min-width: map-get( $grid-breakpoints, lg ) + 1 ) and  ( max-width: map-get( $grid-breakpoints, xl ) + 1 ){
    #main-header{
        .header-text{
            flex: 0 0 85% !important;
            max-width: 85% !important;
        }
        .glide__arrows{
            right: 24px;
            button{
                &.glide__arrow--right{
                    margin-left: 14px !important;
                }
            }
        }
    }
    .glide__arrow{
        .first{
            height: 24px;
            width: 24px;
        }
     &:hover{
        .second{
            height: 24px;
            width: 24px;
        }
     }
    }
    .box-left{
        padding: 16px 20px;
        height: 60px;
        .item{
            padding-right: 16px;
            margin-right: 16px;
            &:first-child{
                padding-right: 24px;
            }
        }
        .arrow{
            margin: 0 8px;
        } 
        #decrease-btn{
            margin-left: 16px;
        }
    }
}
@media screen and ( max-width: map-get( $grid-breakpoints, lg ) + 1 ){  
    .sticky-button{
		right: 0%;
    } 
    .sticky-button{
        bottom: 70vh;
    } 
    // header
    #main-header{
        .header-right{
            p{
                &:first-child{
                    margin: 43px 0 56px;
                }
            }
        }
    }
    .header-text{
        margin-top: -90px;
        .letter-spacing-text{
            white-space: nowrap;
        }
    }
    .header-bottom{
        top: -236px;
        &.active{
            bottom: 0px;
            width: 100%;
            height: 200px;
            background: transparent;
            box-shadow: unset;
            .barboon-box{
                width: 100% !important;
                margin-right: 0;
                align-items: center !important;
                justify-content: center !important;
            }
            .box-left{
                min-width: unset;
                height: auto;
                padding: none;
            }
            #search-btn{
                box-shadow: 2px 3px #888888;
                p{
                    line-height: unset;
                }
            }
        }
        .arrow-right{
            width: 20px;
        }
    }
    .barboon-box{
        margin-right: 0 !important;
        .arrow-right{
            transform: rotate(90deg);
            margin: 15px 0 20px;
            width: 14px;
        }
    }
    .box-left{
        background-color: transparent;
        height: auto;
        border-radius: 0;
        padding: 0;
        .item{
            background-color: #B41A83;
            border-right: none !important;
            padding: 0px !important;
            margin: 5px !important;
            border-radius: 5px;
            height: 50px;
            &:first-child{
                padding: 0 22px 0 25px !important;
            }
            &:nth-child(3){
                p{
                    font-size: 14px;
                }
            }
            &:last-child{
                background-color: #FFFFFF;
                margin-left: 5px !important;
            }
        }
        #search-btn{
            min-width: unset;
            background-color: #FFFFFF;
            border-radius: 7px;
            width: 100%;
            height: 100%;
            p{
                font-size: 14px;        
                color: #B41A83;
                letter-spacing: -1px;
                line-height: 30px;
            }
        }
        .arrow{
            width: 7px;
            height: 12px;
            margin: 0 11px;
            margin-bottom: 2px;
        } 
        .date{
            a{
                padding: 0;
                border: none !important;
                box-shadow: none !important;
                &:focus-visible{
                    outline-offset: 0px !important;
                    outline: 0 !important;
                }
            }
            input{
                border: none;
                background: transparent;
                color: #FFFFFF;
                max-width: 69px;
                min-width: unset;
                padding: 0;
                margin-bottom: 2px;
                font-size: 14px;
                font-family: 'Neurial Grotesk Regular';
                cursor: pointer;
                &::placeholder {
                    color: #FFF;
                    opacity: 1;
                }
                &:-ms-input-placeholder {
                    color: #FFF;
                }
                
                &::-ms-input-placeholder {
                    color: #FFF;
                }
                &:focus-visible{
                    outline-offset: 0px !important;
                    outline: 0 !important;
                }
            }
        }
        #decrease-btn{
            margin-left: 18px;
            font-size: 20px;
        }
        #increase-btn{
            font-size: 20px;
        }
        #guest-count{
            font-size: 14px;
            margin: 0 16px !important;
        }
    }
    .box-right{
        height: 50px;
        max-height: 50px;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        p{
            line-height: normal;
        }
        .money{
            font-size: 14px;
            font-family: 'Neurial Grotesk Medium';
            span{
                font-family: 'Neurial Grotesk Regular';
                font-size: 12px;
                margin-left: 3px;
                margin-right: 0;
                &:last-child{
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }
    .select2{
        display: flex;
        justify-content: space-between;
        width: 100% !important;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b{
        margin-left: 110%;
    }
}
@media screen and ( max-width: map-get( $grid-breakpoints, md ) + 1 ){  
    .box-left{
        .item{
            max-width: 166px !important;
            min-width: 166px !important;
        }
        .date{
            input{
                max-width: 65px;
                margin-bottom: 0px;
                font-size: 12px;
            }
        }
        .arrow{
            width: 5px;
            height: 10px;
            margin: 0px 5px 0 2px;
            margin-bottom: 0px;
        }
    }
    .box-right{
        max-width: 342px !important;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b{
        margin-left: 10%;
    }
}
@media screen and ( max-width: 1024px ) and ( max-height: 750px){  
    #main-header{
        height: 105vh;
    }
}
@media screen and ( max-width: 450px ){
    .sticky-button{
		right: -5%;
    } 
} 
@media screen and ( max-width: 415px ){
    .header-bottom{
        padding: 0 26px !important;
    }
    .box-right{
        max-width: 300px !important; 
    }
    .box-left{
        .item {
            max-width: 141px !important;
            min-width: 141px !important;
        }
        .date{
            input{
                max-width: 60px;
            }
        }
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        margin-left: 10%;
    }
}

.vibration-effect {
    animation: vibration 0.3s infinite;
}

@keyframes vibration {
  0% {
    transform: rotate(270deg);
  }
  25% {
    transform: rotate(268deg);
  }
  50% {
    transform: rotate(273deg);
  }
  75% {
    transform: rotate(268deg);
  }
  100% {
    transform: rotate(273deg);
  }
}