footer{
    color: #FFFFFF;
    overflow: hidden;
    .footer-bg-holder{
        display: flex;
        z-index: -1;
        max-height: 100%;
        top: 0px;
        height: 100%;
        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.5);
            background: linear-gradient(110deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
            backdrop-filter: blur(14px) brightness(1.15);
        }
    }
    .container{
        padding-top: 80px;
    }
    .footer-right-bg{
        z-index: -1;
    }
    .footer-main{
        position: absolute;
        .left{
            position: relative;
            top: 0px;
            left: 0px;
            .h4{
                color: #B41A83;
                font-family: 'Neurial Grotesk Medium';
                line-height: 24px;
                margin-bottom: 10px;
            }
            .h7{
                margin-bottom: 10px;
                color: #000000;
                line-height: 21px;
                font-size: 16px;
            }
            a:hover{
                p{
                    color: #B41A83;
                }
                .white-line{
                    background-color: #B41A83;
                }
            }
            .top{
                div{
                    width: 254px;
                    height: 186px;
                    margin: 0 10px 10px 0;
                    background-color: #FFFFFF;
                    &:first-child{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &:last-child{
                        padding: 21px 0 0 23px;
                        margin-right: 0 !important;
                        background-color: rgba(255, 255, 255, 0.7);
                        backdrop-filter: blur(10px) brightness(1);
                    }
                }
            }
            .bottom{
                div{
                    width: 518px;
                    height: 211px;
                    padding: 30px 49px 0 40px;
                    background-color: rgba(255, 255, 255, 0.7);
                    backdrop-filter: blur(10px) brightness(1);
                }
            }
        }
        .right{
            margin-left: 62px;
            padding: 0;
            position: relative;
            top: 20px;
            p{
                letter-spacing :normal !important;
                font-size: 60px;
            }
        }
        p{
            letter-spacing: -0.5px !important;
            span{
                color: #707070;
            }
        }
    }   
    .footer-bottom{
        padding: 50px 0 55px;
        background-color: #27241E;
        .footer-bottom-top{
            margin-bottom: 60px;
        }
        .bottom-top-top{
            a{
                margin-right: 40px;
                &:last-child{
                    margin-right: 0;
                }
                p{
                    color: #FFFFFF;
                    margin-bottom: 0;
                    font-size: 20px;
                    font-family: 'Neurial Grotesk Medium';
                }
            }
        }
        .bottom-right{
            p{
                font-family: 'Neurial Grotesk Regular';
            }
        }
        .social-media{
            a{
                margin-right: 50px;
                &:last-child{
                    margin-right: 0 !important;
                }
            }
        }
        .right{
            a{
                margin-right: 50px;
                color: #FFFFFF !important;
                &:last-child{
                    margin-right: 0 !important;
                }
                p{
                    color: #FFFFFF !important;
                    font-family: 'Neurial Grotesk Regular';
                }
            }
        }
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, lg) + 1 ){
    footer{
        .footer-bg-holder{
            height: 1100px;
            img{
                position: absolute;
                width: 1562px !important;
                height: 1100px;
                right: -50px;
                object-fit: cover;
                left: unset;
            }
        }
        .container{
            padding-top: 40px;
            padding-bottom: 40px;
            .bg-holder{
                display: none;
            }
        }
        .footer-right-bg{
            z-index: -1;
        }
        .footer-main{
            position: relative;
            .left{
                position: relative;
                top: unset;
                left: unset;
                .h4{
                    color: #B41A83;
                    font-family: 'Neurial Grotesk Medium';
                    line-height: 24px;
                    margin-bottom: 10px;
                }
                .h7{
                    margin-bottom: 10px;
                    color: #000000;
                    line-height: 21px;
                }
                a:hover{
                    p{
                        color: #B41A83;
                    }
                    .white-line{
                        background-color: #B41A83;
                    }
                }
                .top{
                    div{
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        background-color: #FFFFFF;
                        &:first-child{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            padding: 53.6px 0;
                            background-color: #F4F5FA;
                        }
                        &:last-child{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            margin: 10px 0;
                            padding: 21px 40px 34px;
                            margin-right: 0 !important;
                        }
                    }
                }
                .bottom{
                    div{
                        width: 100%;
                        height: 100%;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 21px 23px 34px 40px;
                        margin-right: 0 !important;
                    }
                }
            }
            .right{
                margin-left: 0px;
                padding: 0;
                position: relative;
                top: unset;
                max-width: 75%;     
                margin-bottom: 43px !important;       
                .h1{
                    line-height: 47px;
                    margin-bottom: 0;
                }
            }
        }
        .footer-bottom{
            padding: 10px 0 40px;
            .footer-bottom-top{
                margin-bottom: 80px;
            }
            .bottom-top-top{
                a{
                    margin-top: 30px;
                    margin-right: 0px;
                    display: flex;
                    justify-content: center;
                    p{
                        min-width: 108px;
                    }
                }
            }
            .bottom-right{
                p{
                    font-size: 16px;
                }
            }
            .social-media{
                margin: 40px 0;
                a{
                    margin-right: 50px;
                    &:last-child{
                        margin-right: 0 !important;
                    }
                }
            }
            .mobile-right{
                a{
                    margin-bottom: 20px;
                    color: #FFFFFF !important;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    p{
                        color:#FFFFFF !important;
                        font-family: 'Neurial Grotesk Regular';
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media screen and ( max-width: map-get( $grid-breakpoints, sm) + 1 ){
    footer{
        .footer-bottom{
            .bottom-top-top{
                a{
                    p{
                        text-align: center;
                        min-width: unset;
                        font-size: 14px;
                    }
                }   
            }   
        } 
    } 
}