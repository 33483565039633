$env: "production";
// Global
@import "../../base/global";
@import "../../base/shared/blog-slider";

.sticky-button {
  opacity: 0;
  &.active {
    opacity: 1;
  }
}

.header-bottom {
  transition: unset !important;
  &.active {
    transition: all 0.5s ease !important;
  }
}
// test
// Experience Section
#experience {
  position: relative;
  padding: 0;
  margin-top: 120px;
  padding-bottom: 120px;
  .top {
    .letter-spacing-text {
      margin-bottom: 10px !important;
    }
    .left {
      p {
        font-size: 24px;
        margin-bottom: 0 !important;
      }
      .experience {
        color: #5f6738;
      }
    }
    .right {
      p {
        font-size: 16px;
        line-height: 21px;
        color: #231f20;
      }
    }
  }
  .bottom {
    margin-top: 76px;
    height: 380px;
    .left {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: start;
      height: 100%;
      &::after {
        content: "";
        background-image: url("../../img/L-leke.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 20px;
        right: 0px;
        width: 20px;
        height: 30px;
      }
      &::before {
        content: "";
        background-image: url("../../img/L-leke.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 10px;
        left: 0px;
        transform: rotate(180deg);
        width: 20px;
        height: 30px;
      }
      p {
        color: #707070;
        line-height: 28px;
      }
      a {
        p {
          margin-bottom: 25px;
          color: #b41a83;
        }
      }
    }
    .right {
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// Accommodation
#accommodation,
#accom {
  padding-top: 0px;
  .top {
    background-color: #f4f5fa;
    height: 510px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 41px;
    p {
      font-size: 24px;
    }
    img {
      margin: 40px 0 0 22.5px;
    }
  }
  .bottom {
    margin-top: -235px;
    #accommodation-slide {
      height: 424px;
      display: flex;
      align-items: flex-end;
      .glide__slides {
        align-items: flex-end;
        height: 100%;
      }
      .glide__slide {
        .accommodation-item, .accom-item{
          display: flex;
          width: 100%;
          object-fit: cover;
          transition: all 0.5s linear;
          border-radius: 40px;
          height: 271px;
          position: relative;
          .hotel-name {
            left: -1px;
            bottom: -1px;
            height: 62px;
            padding: 0 28px 0 23px;
            background-color: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(20px) brightness(1);
            border-radius: 10px;
            transition: all 0.5s linear;
            p {
              font-size: 20px;
              line-height: 27px;
              margin-bottom: 0;
              white-space: nowrap;
            }
            .hotel-description {
              font-size: 14px;
              line-height: 21px;
            }
          }
          p {
            font-size: 14px;
            transition: visibility 0.5s linear;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
        }
        &--active {
          .accommodation-item, .accom-item{
            height: 424px;
            width: 156%;
            margin-left: -28%;
            .hotel-name {
              height: 90px;
              padding: 0 49px 0 34px;
              p {
                font-size: 28px;
                line-height: 37px;
                margin-bottom: 0;
              }
              .hotel-description {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
  .glide__arrows {
    position: absolute;
    left: calc(50% - 57px);
  }
}

// Experience Slider Section
#experience-slider {
  .top {
    .right {
      p {
        color: #231f20;
      }
    }
  }
  .bottom {
    margin-top: 75px;
  }
  .glide__slides {
    img {
      border-radius: 10px;
      object-fit: cover;
      max-width: 640px;
      height: auto;
      width: 100%;
    }
  }
  .right {
    .left {
      position: absolute;
      left: -71%;
      top: 30px;
      &::after {
        content: "";
        background-image: url("../../img/L-leke.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: -30px;
        right: -100px;
        width: 20px;
        height: 30px;
      }
      &::before {
        content: "";
        background-image: url("../../img/L-leke.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        bottom: -40px;
        left: -20px;
        transform: rotate(180deg);
        width: 20px;
        height: 30px;
      }
      .glide__bullets {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        .glide__bullet {
          margin: 0 0 14px 0;
          background-color: transparent !important;
          height: unset;
          width: unset;
          border-radius: 0px;
          p {
            text-align: left;
            margin-bottom: 0;
            line-height: 21px;
            color: #1d1d1d;
            white-space: nowrap;
          }
          &--active {
            background-color: transparent !important;
            height: unset;
            width: unset;
            border-radius: 0px;
            p {
              color: #b41a83;
            }
          }
        }
      }
    }
  }
}
#experience-slide {
  .glide__bullets {
    display: flex;
    .glide__bullet {
      margin: 0;
      background-color: #b8babc;
      height: 0.5px;
      width: 50px;
      border-radius: 100px !important;
      &.glide__bullet--active {
        background-color: #b41a83;
        height: 3px;
        width: 50px;
        border-radius: 100px;
        margin-top: -1px;
      }
    }
  }
}

// Hotel Slider
#hotel-packages {
  background-color: #f4f5fa;
  height: auto;
  padding-bottom: 66px;
  .top {
    width: 100%;
    padding-top: 41px;
    .green-text {
      font-family: "Neurial Grotesk Medium";
      color: #303e48 !important;
    }
    p {
      color: #231f20;
    }
  }
  .blured-slider {
    position: relative;
    width: calc(100% - 204px);
    margin: 0 auto;
    .glide__track {
      padding-top: 60px;
    }
    .glide__slides {
      height: 515px;
      align-items: center;
      overflow: visible;
    }
    .glide__slide {
      height: auto;
      .hotel-item {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: blur(5px);
        transition: all 0.5s linear;
        border-radius: 10px;
        .slider-top {
          max-height: 278px;
          width: 100%;
          background-color: #ffffff;
          border-radius: 10px 10px 0 0;
          img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
        }
        .slider-bottom {
          min-height: 237px;
          padding: 40px 40px 0;
          background-color: #ffffff;
          border-radius: 0 0 10px 10px;
          p {
            color: #000000;
            &:nth-child(2) {
              margin: 31px 0 45px;
              line-height: 21px;
            }
          }
          a {
            color: #b41a83 !important;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      &--active {
        z-index: 1;
        .hotel-item {
          width: 154.5%;
          margin-left: -27.25%;
          filter: blur(0px);
          box-shadow: #00000016 0px 0px 50px;
        }
      }
    }
  }
  .glide__arrows {
    position: absolute;
    left: calc(50% - 57px);
  }
}
// video section
#video-text {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
  .bg-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    video {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translate3d(0, 0, 0);
    }
  }
  .video-main {
    position: absolute;
    top: 14vh;
    left: 13vh;
    color: #ffffff;
    padding: 40px 52px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px) brightness(1.15);
    max-width: 480px;
    &::after {
      content: "";
      background-image: url("../../img/L-leke.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 30%;
      right: 13px;
      width: 20px;
      height: 30px;
    }
    &::before {
      content: "";
      background-image: url("../../img/L-leke.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      bottom: 16px;
      left: 16px;
      transform: rotate(180deg);
      width: 20px;
      height: 30px;
    }
  }
}

// tab section
#culture-tab {
  .bottom {
    margin-top: 63px;
    .cart-tabs-header {
      border-bottom: 0.5px solid #707070;
      padding: 0 30px;
      margin-bottom: 80px;
      .accordion-box {
        p {
          margin-bottom: 0;
          padding-bottom: 14px;
        }
        &[aria-expanded="true"]::after {
          content: "";
          display: flex;
          background-color: #b41a83;
          height: 5px;
          width: 120%;
          border-radius: 100px;
          margin-left: -10%;
          bottom: -3px;
          z-index: 1;
          position: relative;
        }
      }
    }
    .left-right-component {
      .left {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          border-bottom: 1px solid #707070;
        }
      }
      .right {
        height: fit-content;
        .letter-spacing-text {
          font-family: "Neurial Grotesk Medium";
        }
        .h3 {
          line-height: 32px;
        }
        .h7 {
          margin: 54px 0 40px;
          line-height: 26px;
        }
        &::after {
          content: "";
          background-image: url("../../img/L-leke.png");
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          top: 0px;
          right: 0px;
          width: 20px;
          height: 30px;
        }
        &::before {
          content: "";
          background-image: url("../../img/L-leke.png");
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          bottom: -30px;
          left: -17px;
          transform: rotate(180deg);
          width: 20px;
          height: 30px;
        }
      }
    }
  }
}

// accom section - newly added
#accom {
  // TODO: CHECK THIS LATER
  background-color: #f4f5fa;
  // margin: 60px 0;
  padding-bottom: 120px;
  .bottom {
    margin-top: -320px;
  }
  .top {
    background-color: #f4f5fa;
    // height: 510px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 41px;
    p {
      font-size: 24px;
    }
    img {
      margin: 40px 0 0 22.5px;
    }
  }
  #accom-slide {
    // TODO: CHECK THIS LATER
    height: 424px;
    .glide__slides {
      // padding-top: 72px;
      display: flex;
      align-items: flex-end;
    }
    .glide__arrows {
      margin-top: 52px;
    }

    .glide__slide {
      .accom-item {
        display: flex;
        width: 100%;
        object-fit: cover;
        transition: all 0.5s linear;
        border-radius: 40px;
        height: 271px;
        position: relative;
        .hotel-name {
          left: -1px;
          bottom: -1px;
          height: 62px;
          padding: 0 28px 0 23px;
          background-color: rgba(255, 255, 255, 0.4);
          backdrop-filter: blur(20px) brightness(1);
          border-radius: 10px;
          transition: all 0.5s linear;
          p {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 0;
            white-space: nowrap;
          }
          .hotel-description {
            font-size: 14px;
            line-height: 21px;
          }
        }
        p {
          font-size: 14px;
          transition: visibility 0.5s linear;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      &--active {
        .accom-item {
          height: 424px;
          width: 156%;
          margin-left: -28%;
          .hotel-name {
            height: 90px;
            padding: 0 49px 0 34px;
            p {
              font-size: 28px;
              line-height: 37px;
              margin-bottom: 0;
            }
            .hotel-description {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

// newly added
@media screen and (min-width: 1000px) {
  #accom {
    #accom-slide {
      // TODO: CHECK THIS LATER
      height: 424px;

      ul {
        height: 100%;

        li {
          display: flex;
          align-items: flex-end;
        }
      }

      .glide__track {
        height: 424px;
      }

      .glide__slide {
        &--active {
          .accom-item {
            height: 424px;

            width: 156% !important;
            margin-right: -28%;
            margin-left: -28%;
          }
        }
      }
    }
  }
}

// customers
#customers {
  background-color: #f4f5fa;
  margin: 60px 0;
  padding-bottom: 80px;
  .top {
    .col-lg-6 {
      p {
        color: #231f20;
      }
    }
  }
  #customers-slide {
    margin-top: 80px;
    .glide__slides {
      padding-top: 72px;
    }
    .customers-slider-item {
      position: relative;
      height: 280px;
      background-color: #ffffff;
      padding: 98px 16px 31px 20px;
      border-radius: 10px;
      img {
        position: absolute;
        left: calc(50% - 65px);
        top: -71px;
        width: 129px;
        height: 129px;
        border-radius: 50%;
        object-fit: cover;
      }
      p {
        line-height: 21px;
        color: #6b6b6c;
      }
      .h7 {
        font-family: "Neurial Grotesk Medium";
        color: #6b6b6c;
        line-height: 22px;
      }
      .country {
        font-size: 14px;
        color: #6b6b6c;
        line-height: 17px;
      }
      &::after {
        content: "";
        background-image: url("../../img/L-leke.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 15px;
        right: 20px;
        width: 20px;
        height: 30px;
      }
    }
    .glide__bullets {
      display: flex;
      margin-top: 70.5px;
      .glide__bullet {
        margin: 0;
        background-color: #b8babc;
        height: 0.5px;
        width: 50px;
        border-radius: 100px !important;
        &.glide__bullet--active {
          background-color: #b41a83;
          height: 3px;
          width: 50px;
          border-radius: 100px;
          margin-top: -1px;
        }
      }
    }
    .glide__arrows {
      margin-top: 52px;
    }
  }
}
// app
#app {
  height: 564px;
  padding: 0px;
  margin-top: 48px;
  .left {
    a {
      img:first-child {
        margin-right: 16px;
      }
    }
    .h3 {
      color: #303e48;
    }
    .h7 {
      color: #707070;
      margin: 40px 0 30px;
    }
  }
  .app-phone {
    position: absolute;
    height: 100%;
    right: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

#work-with-us {
  background-color: #303e48;
  color: #ffffff;
  min-height: 420px;
  .letter-spacing-text {
    color: #ffffff;
  }
}

#faq {
  padding: 80px 0;
  .accordion-item {
    border-bottom: 1px solid #dee2f7;
    // display: none !important;
    &.active {
      display: flex !important;
      flex-direction: column;
    }
  }
  #see-more {
    &.active {
      display: none !important;
    }
  }
  .accordion-button {
    padding: 38px 0 31px 0;
    .h3 {
      color: #b41a83;
    }
    &[aria-expanded="true"] {
      p {
        font-weight: bold;
      }
    }
  }
  .accordion-body {
    margin-bottom: 65px;
  }
  .accordion-header {
    width: 100%;
    p {
      color: #1d1d1d;
      &:hover {
        color: #b41a83;
      }
    }
  }
}

.glide__arrow {
  .first {
    display: flex;
    opacity: 1;
    height: 35px;
    width: 35px;
  }
  .second {
    display: none;
    opacity: 0;
    height: 0px;
    width: 0px;
  }
  &:hover {
    .first {
      display: none;
      opacity: 0;
      height: 0px;
      width: 0px;
    }
    .second {
      display: flex;
      opacity: 1;
      height: 35px;
      width: 35px;
    }
  }
}

@import "media";

#form-success-container {
  min-height: 300px;

  .right {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
}

.model-title,
.model-body {
  color: #000000 !important;
}
