
@font-face {
    font-family: 'Neurial Grotesk Bold';
    src: url('../../fonts/NeurialGrotesk-Bold.otf') format('otf'),
        url('../../fonts/NeurialGrotesk-Bold..woff') format('woff'),
        url('../../fonts/NeurialGrotesk-Bold..eot') format('eot'),
        url('../../fonts/NeurialGrotesk-Bold..ttf') format('tff'),
        url('../../fonts/NeurialGrotesk-Bold..woff2') format('woff2');
}

@font-face {
    font-family: 'Neurial Grotesk Light';
    src: url('../../fonts/NeurialGrotesk-Light.otf') format('otf'),
        url('../../fonts/NeurialGrotesk-Light.woff') format('woff'),
        url('../../fonts/NeurialGrotesk-Light.eot') format('eot'),
        url('../../fonts/NeurialGrotesk-Light.tff') format('tff'),
        url('../../fonts/NeurialGrotesk-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Neurial Grotesk Medium';
    src: url('../../fonts/NeurialGrotesk-Medium.otf') format('otf'),
        url('../../fonts/NeurialGrotesk-Medium.woff') format('woff'),
        url('../../fonts/NeurialGrotesk-Medium.eot') format('eot'),
        url('../../fonts/NeurialGrotesk-Medium.tff') format('tff'),
        url('../../fonts/NeurialGrotesk-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Neurial Grotesk Regular';
    src: url('../../fonts/NeurialGrotesk-Regular.otf') format('otf'),
        url('../../fonts/NeurialGrotesk-Regular.woff') format('woff'),
        url('../../fonts/NeurialGrotesk-Regular.eot') format('eot'),
        url('../../fonts/NeurialGrotesk-Regular.tff') format('tff'),
        url('../../fonts/NeurialGrotesk-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Sequel Sans Bold Body';
    src: url('../../fonts/SequelSans-BoldBody.otf') format('otf'),
        url('../../fonts/SequelSans-BoldBody.woff') format('woff'),
        url('../../fonts/SequelSans-BoldBody.eot') format('eot'),
        url('../../fonts/SequelSans-BoldBody.tff') format('tff'),
        url('../../fonts/SequelSans-BoldBody.woff2') format('woff2');
}

.h1{
    font-size: 48px;
    font-family: 'Sequel Sans Bold Body';
}
.h2{
    font-size: 30px;
    font-family: 'Sequel Sans Bold Body';
}
.h3{
    font-size: 24px;
    font-family: 'Neurial Grotesk Medium';
}
.h4{
    font-size: 20px;
    font-family: 'Neurial Grotesk Regular';
}
.h5{
    font-size: 16px;
    font-family: 'Neurial Grotesk Light';
}
.h6{
    font-size: 12px;
    font-family: 'Neurial Grotesk Medium';
}
.h7{
    font-size: 18px;
    font-family: 'Neurial Grotesk Regular';
}
p{
    font-size: 16px;
    line-height: 23px;
    font-family: 'Neurial Grotesk Regular';
}
a{
	color: #000000 !important;
	text-decoration: none !important;
}
.letter-spacing-text{
	font-size: 12px !important;
	color: #B41A83;
	letter-spacing: 4px;
    font-family: 'Neurial Grotesk Regular';
}
.green-text{
	color: #5F6738 !important;
    font-family: 'Neurial Grotesk Regular';
	font-size: 24px;
    line-height: 29px;
}
.white-line{
	height: 1px;
	width: 46px;
	background-color: #FFFFFF;
}
.purple-line{
	height: 1px;
	width: 46px;
	background-color: #B41A83;
}
.gray-text{
    color: #6F7070;
}

.purple-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    background-color: #B41A83;
    padding: 0 48px;
    color: #FFF !important;
    border-radius: 5px;
    transition: all .4s ease-in-out;
    &:hover{
        opacity: .74;
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, lg ) + 1 ){
    .h1{
        font-size: 40px !important;
    }
    .letter-spacing-text{
        font-size: 14px !important;
        margin-bottom: 8px;
    }
    .green-text{
        font-family: 'Neurial Grotesk Light';
        font-size: 20px;
        line-height: 24px;
    }
}