.form-control {
  border: 5px solid #c9c9c9;
  border-radius: 50px;
  padding: 25px 35px;
  box-shadow: #9a9a9a29 10px 12px 10px;
  &:focus {
    border-color: #c9c9c9 !important;
    box-shadow: none !important;
  }
  &.black {
    color: #58595b !important;
    border: 3px solid #58595b !important;
    &:focus {
      border-color: #58595b !important;
    }
  }
}
.select-wp {
  display: block;
  position: relative;
  img {
    width: 37px;
    position: absolute;
    top: 9px;
    right: 25px;
    pointer-events: none;
  }
  select {
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #000;
    height: 48px;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  &.is-invalid {
    & + .text-danger {
      display: flex;
      margin-top: 5px;
    }
  }
}
.message {
  border-radius: 30px !important;
  textarea {
    height: 227px !important;
  }
}
.photo-upload-box {
  background-color: #c9c9c9;
  height: 40px;
  border-radius: 33px;
  padding: 0 10px;
}

#error-message {
  &.active {
    display: flex !important;
  }
}
