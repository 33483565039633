nav{
    height: 108px;
    z-index: 99999;
    .navbar-left{
        a{
            top: 0;
        }
    }
}
.navbar-right{
    li{
        padding-right: 38px;
        p:hover{
            color: #B41A83;
        }
        &:last-child{
            padding-right: 33px;
        }
    }
    .navbar-right-left{
        border-right: 1px solid #DDDDDD;
    }
    .navbar-right-right{
        padding-left: 38px;
        .dropdown{
            padding-left: 24px;
        }
        .check-in-nav{
            background-color: #B41A83;
            padding: 0 15px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            p{
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                color: #FFFFFF;
            }
        }
    }
    .dropdown-menu{
        min-width: 65px !important;
        max-width: 65px !important;
        height: 34px;
        background-color: #EBEBF0;
        font-size: 14px;
        font-family: 'Neurial Grotesk Regular';
        margin-top: 0;
        left: -20px;
        top: 23px;
        border: none !important;
        padding-top: 6px;
        p{    
           margin-left: -2px;
        }
    }
}
.nav-link{
    &[aria-expanded="true"]::after{
        transform: rotate(180deg) scaleX(-1);
    }
    &::after{
        content: "";
        background-image: url('../../img/arrow-down.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 7px;
        height: 3px;
        border: none !important;
        transition: transform .3s linear;
    }
}

#mobile-navbar-btn{
    &.hide{
        display: none;
    }
}

#close-btn{
    display: none;
    &.show{
        display: flex;
    }
}

body{
    &.active{
        height: 100vh;
        overflow: hidden;
    }
}

#mobile-navbar{
    height: calc( 100% - 106px );
    width: 100%;
    padding-bottom: 100px;
    background-color: #F4F5FA;
    overflow: auto;
    position: absolute;
    bottom: 0;
    z-index: 9;
    transform: translateX(-100%);
    transition: transform .3s ease-out;
    &.active{
        transform: translateX(0%);
    }
    .main-mobile-navbar{
        margin-top: 80px;
        position: relative;
        a{
            margin-bottom: 40px;
        }
        span{
            font-family: 'Neurial Grotesk Medium';
            font-size: 16px;
            line-height: 21px;
        }
        &::after{
            content: "";
            background-image: url('../../img/L-leke.png');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: -20px;
            right: 0px;
            width: 20px;
            height: 30px;
        }
        &::before{
            content: "";
            background-image: url('../../img/L-leke.png');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            bottom: -30px;
            left: 0px;
            transform: rotate(180deg);
            width: 20px;
            height: 30px;
        }
    }
}

.mobile-navbar-bottom{
    margin-top: 80px;
    .top{
        .dropdown-toggle{
            width: 86px;
            height: 40px;
            border-radius: 10px;
            background-color: #FFFFFF;
            font-size: 14px;
            font-family: 'Neurial Grotesk Regular';
            transition: all .6s linear;
            &::after{
                content: "";
                background-image: url('../../img/arrow-down.png');
                background-repeat: no-repeat;
                background-size: cover;
                width: 8px;
                height: 5px;
                margin-left: 9px;
                border: none !important;
                transition: transform .3s linear;
            }
            &[aria-expanded="true"]{
                background-color: #EBEBF0;
            }
        }
        [aria-expanded="true"]::after{
            transform: rotate(180deg) scaleX(-1);
        }
        .dropdown-menu{
            min-width: 86px !important;
            max-width: 86px !important;
            height: 40px;
            border-radius: 10px;
            background-color: #FFFFFF;
            font-size: 14px;
            font-family: 'Neurial Grotesk Regular';
            margin-top: 0;
            top: 5px !important;
            border: none !important;
            p{    
               margin-left: -2px;
            }
        }
    }
    .bottom{
        margin-top: 20px;
        a{
            border-radius: 50px;
            background-color: #B41A83;
            height: 52px;
            width: 52px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and ( max-width: 1260px) and ( min-width: 1000px ){   
    .navbar-left{
        img{
            height: 145px;
            width: 145px;
        }
    }
}
@media screen and ( max-width: map-get( $grid-breakpoints, sm ) + 1 ){   
    .navbar-left{
        padding-left: 36px;
        img{
            height: 145px;
            width: 145px;
        }
    }
    .navbar-mobile-right{
        padding-right: 36px;
    }
}