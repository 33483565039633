// Required
@import "node_modules/bootstrap/scss/functions";
@import "./variables";
@import "node_modules/bootstrap/scss/mixins";

// Components & Utils
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/print";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/utilities";
@import "typography";
@import "./modules/content-animation";
@import "./modules/datepicker";

// Glide JS
@import "../../base/modules/glide/glide-core";
@import "../../base/modules/glide/glide-theme";

//Shared
@import "shared/nav";
@import "shared/footer";
@import "shared/form";

// Environments
@import "./env";
@import "./typography";
@import "./shared/nav";
@import "./shared/footer";
@import "./shared/checkInBtn";

html,
body {
  min-width: 100%;
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  &.sticky-btn-active{
    .select2-results::after {
      top: auto;
      bottom: 2px;
      z-index: 99999;
    }
    .select2-results__options {
      top: -6px;
  }
  }
}

.form-group {
  flex-direction: column;
}
.form {
  .form-error {
    display: none;
  }
  &.error {
    .form-error {
      display: block;
    }
  }
  .form-group {
    flex-direction: column;
    .text-danger {
      display: none;
    }
    &.is-invalid {
      .text-danger {
        display: block;
      }
    }
  }
}

.glide {
  .glide__arrow {
    color: $black;
  }
}

section {
  padding: 60px 0;
}

ul {
  margin-bottom: 0px;
}

li {
  list-style-type: none !important;
  marker: none !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent;
}

a:hover {
  p {
    color: #b41a83;
  }
  .white-line {
    background-color: #b41a83;
  }
}

.glide__arrows {
  display: flex;
  button {
    border-radius: 50px;
    padding: 0;
    &.glide__arrow--left {
      margin-right: 40px;
      transform: rotate(180deg);
    }
  }
}

.glide__bullets {
  display: flex;
  .glide__bullet {
    margin: 0;
    background-color: #b8babc;
    height: 0.5px;
    width: 50px;
    border-radius: 100px !important;
    &.glide__bullet--active {
      background-color: #b41a83 !important;
      height: 3px;
      width: 50px;
      border-radius: 100px;
    }
  }
}

#work-with-us {
  .modal-content {
    color: #000000 !important;
  }
  #stickyCountryCode {
    display: none;
    .selected-flag {
      width: 10px;
      height: 10px;
    }
  }
  .select2 {
    width: 135px !important;
    max-width: 135px !important;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-bottom: 1px solid #ffffff;
  }
  #succes-message {
    display: none;
    p {
      color: #4caf50;
      font-size: 14px;
      font-family: "Neurial Grotesk Regular";
    }
  }
  #error-message {
    display: none;
    p {
      color: #ff0000;
      font-size: 14px;
      font-family: "Neurial Grotesk Regular";
    }
  }
  .form-control {
    border: none;
    background: unset;
    box-shadow: none;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    padding-left: 15px;
    color: #ffffff;
    font-size: 16px;
    font-family: "Neurial Grotesk Regular";
  }
  .form-group {
    small {
      display: none;
      color: #f44336;
      margin-top: 6px;
      font-size: 12px;
      font-family: "Neurial Grotesk Regular";
      &.active {
        display: flex;
      }
    }
  }
  .form-check-label {
    small {
      display: none;
      color: #f44336;
      margin-top: 6px;
      font-size: 12px;
      font-family: "Neurial Grotesk Regular";
      &.active {
        display: flex;
      }
    }
  }
  .left {
    margin-top: 10px;
    position: relative;
    height: fit-content;
    .h3 {
      margin-bottom: 18px;
    }
    &::after {
      content: "";
      background-image: url("../../img/L-leke.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -10px;
      right: -20px;
      width: 20px;
      height: 30px;
    }
    &::before {
      content: "";
      background-image: url("../../img/L-leke.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      bottom: -30px;
      left: -20px;
      transform: rotate(180deg);
      width: 20px;
      height: 30px;
    }
  }
  #phone {
    // padding-left: 15px !important;
  }
  input {
    border: none;
    background: unset;
    box-shadow: none;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    padding: 0;
    color: #ffffff;
    font-size: 16px;
    font-family: "Neurial Grotesk Regular";
    &::placeholder {
      color: #ffffff;
      font-size: 16px;
      font-family: "Neurial Grotesk Regular";
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #ffffff;
      font-size: 16px;
      font-family: "Neurial Grotesk Regular";
    }

    &::-ms-input-placeholder {
      color: #ffffff;
      font-size: 16px;
      font-family: "Neurial Grotesk Regular";
    }
  }
  .bottom-left {
    .checkbox {
      margin-right: 12px;
    }
    a {
      color: #ffffff !important;
      p {
        color: #ffffff !important;
      }
      &:hover {
        p {
          color: #ffffff !important;
        }
      }
    }
    .form-check-label {
      a {
        font-size: 12px;
        font-family: "Neurial Grotesk Regular";
        text-decoration: underline !important;
      }
    }
    label {
      margin-bottom: 0;
    }
  }
  #form-btn {
    background-color: #b41a83;
    padding: 6px 13px 6px 7px;
    border-radius: 14px;
    color: #ffffff !important;
    border: none !important;
    font-size: 14px;
    font-family: Neurial Grotesk Regular;
    &:hover {
      p {
        color: #ffffff !important;
      }
    }
    p {
      margin-left: 5px !important;
      font-size: 14px;
    }
    img {
      margin-right: 5px !important;
    }
  }
}

.button {
  border-radius: 57px;
  color: $white;
  padding: 0px 42px;
  height: 40px;
}

.modal-active {
  overflow: hidden;
}

@media screen and (max-width: map-get( $grid-breakpoints, lg ) + 1) {
  section {
    padding: 40px 0;
  }
  .button {
    padding: 0px 24px !important;
    height: 25px;
  }
}
@media screen and (max-width: map-get( $grid-breakpoints, sm ) + 1) {
  .container {
    padding: 0 36px;
  }
}

.select2 {
  cursor: pointer;
  width: auto !important;
}

.select2-search {
  display: none;

  height: 0;
}

.span#select2-stickyCountryCode-container {
  margin: 20px;
}

span#select2-stickyCountryCode-container {
  font-size: 16px;

  letter-spacing: -1px;

  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Inter Regular";
  .selected-flag {
    margin-right: 10px;
  }

  // display: flex;
  // align-items: center;
}
.select2-results{
  position: relative;
  &::after{
    content: "";
    position: absolute;
    z-index: 1;
    top: 5px;
    left: -4px;
    width: 0px;
    height: 0px;
    border-left: 8.5px solid transparent;
    border-right: 8.5px solid transparent;
    border-bottom: 8.5px solid #F7F7F7;
    transform: rotate(90deg);
  }
  &::before{
      content: "";
      position: absolute;
      z-index: 1;
      top: 9px;
      right: 0;
      width: 100%;
      height: 5px;
      background-color: #F7F7F7;
  }
}
.select2-results__options{
  max-height: 164px;
  width: auto;
  padding: 20px 22px;
  overflow: auto;
  background-color: #F7F7F7;
  position: relative;
  z-index: 99999;
  top: 10px;
  li{
    background-color: #F7F7F7;
    color: #1D1D1D;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 15px;
    font-family: 'Neurial Grotesk Regular';
    cursor: pointer;
    &:last-child{
        margin-bottom: 0;
    }
    span{
      display: flex;
      align-items: center;
      span{
        margin-left: 10px;
      }
    }
  }
}

#select2-popupCountryCode-container {
  img {
    height: 34px;

    width: auto;

    object-fit: cover;
  }
}

#select2-stickyCountryCode-container {
  img {
    height: 25px;

    width: auto;

    object-fit: cover;
  }
}

.selection {
  position: relative;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  content: "";

  //   position: absolute;

  //   background-image: url("../../img/form-dropdown.svg");

  //   background-repeat: no-repeat;

  //   background-size: cover;

  //   top: 49%;

  //   width: 9px;

  //   height: 5px;

  //   margin-left: 15px;

  //   transition: transform 0.3s linear;
}

span {
  &.label-container {
    text-decoration: underline;
  }
}

.form-sent-success-btn {
  background-color: #b41a83;
  padding: 0 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 87px;
  height: 24px;

  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #ffffff;
  }

  &:hover {
    p {
      color: #ffffff !important;
    }
  }
}

.pagination-holder{
	margin-top: 120px;
	#previousPage{
		margin-right: 35px;
		cursor: pointer;

	}
	#nextPage{
		margin-left: 35px;
		cursor: pointer;
    img{
			transform: rotate(180deg);
		}
	}
	#pagination{
		.page-item{
			font-size: 32px;
			line-height: 39px;
			letter-spacing: -1px;
			opacity: .4;
			margin-right: 32px;
			color: #b41a83;
			cursor: pointer;
			&:last-child{
				margin-right: 0;
			}
			&.active{
				opacity: 1;
				pointer-events: none;
			}
		}
	}
}
